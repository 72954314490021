import React,{useState} from 'react';
import { Button, Form} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { LoginScreenWrap } from "./LoginPage.styles";
import { Images } from '../../Assets/images';
import { handleFinish, handleFinishFailed } from '../../Helpers/LoginPageHelper';
import {AntdInputFormItem,AntdSpin} from '../../Components';
import LOGIN_VISITOR_FIELDS from '../../Constants/LOGIN_VISITOR_FIELDS ';
import { CloseOutlined } from "@ant-design/icons";
import { ROUTES_URL } from '../../Constants/navigationUrls';
import { Link } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false)
  return (
    loading ? (
      <AntdSpin /> // Show spinner while loading
    ) : (
    <LoginScreenWrap>
      <div className="login-page">
       
        <div className="login-box">
        {/* <Link to={ROUTES_URL.USER_ENTRY_PAGE} className="user-checkIn-close">
            <CloseOutlined
              style={{ right: 10, top: 10, color: "#000", fontSize: "30px", position: 'absolute' }} // Ensure position is absolute
            />
          </Link> */}
          <div className="illustration-wrapper">
            <img 
              src={Images.niveus_solutions_logo} 
              alt="Login"
              className="login-illustration"
            />
          </div>
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={(values) => handleFinish(values, login, navigate,setLoading)}
            onFinishFailed={handleFinishFailed}
            layout="vertical"
          >
            <p className="form-title">Welcome</p>
            <p className="form-subtitle">Login to the Visitor App</p>

            <AntdInputFormItem item={LOGIN_VISITOR_FIELDS.username}/>
            <AntdInputFormItem item={LOGIN_VISITOR_FIELDS.password}/>
      
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </LoginScreenWrap>
    )
  );
};

export default LoginPage;