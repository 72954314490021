import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_URL } from '../Constants/navigationUrls';

export const useRedirectAfterDelay = (delay = 30000) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(ROUTES_URL.USER_ENTRY_PAGE); 
    }, delay); // delay milliseconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [navigate, delay]);
};
