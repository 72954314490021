import styled from 'styled-components';
import { theme } from '../../styles/theme/theme'; // Adjust path as necessary

export const UserEntryScreenWrap = styled.div`
  .user-entry-screenwrap {
      background: #d9edff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: ${theme.colors.secondary};
    height: 100vh;
    justify-content: center;
  }

  .visitor-form-container {
    max-width: 500px;
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .highlight {
    color: ${theme.colors.primary};
  }

  .form-image {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
  }

  .form-card {
    background: ${theme.colors.secondary};
    padding: 20px;
    // border-radius: 8px;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

   .form-submit-button {
   width: 100%;
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
    &:hover {
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
          width: 100%;
    }
  }
  .user-checkIn-close{
    display: flex;
    color: unset;
    text-decoration: none;
    justify-content: space-between;
  }
`;
