import axios from "axios";
import { BASE_ROUTE } from "./routes";

const callPostApi = async (endpoint, formData) => {
  try {
  
    const response = await axios.post(`${BASE_ROUTE}/${endpoint}`, formData, {});

    return response.data;
  } catch (error) {
    console.error(`Error in calling ${endpoint} API:`, error);
    throw error;
  }
};

// Specific API functions using the generalized function
export const UserCheckInCheckOutApi = async (formData) => {
  return callPostApi('check-in-check-out', formData);
};

export const UserCheckOutApi = async (formData) => {
  return callPostApi('check-out', formData);
};

export const UserCheckIntApi = async (formData) => {
  return callPostApi('check-in', formData);
};

export const VisitorAppLoginApi = async (formData) => {
  return callPostApi('visitorApp-login', formData);
};

export const UserFetchUserInfoApi = async () => {
  
  try {
    const response = await axios.get(`${BASE_ROUTE}/fetchUserInfoApi`);
    return response.data.data.map((name) => ({
      label: name.emailId,
      value: name.emailId
    }));
    
  } catch (error) {
    console.error("Error in   calling check-in Api Call:", error);
    throw error;
  }
};

export const UserGetAllPurposeApi = async (formData) => {
  try {

    const response = await axios.get(`${BASE_ROUTE}/getAll-purpose`);
 
    return response.data.data.map((purpose) => ({
      label: purpose.purpose,
      value: purpose.purpose
    }));
  } catch (error) {
    console.error("Error in   calling check-in Api Call:", error);
    throw error;
  }
};