import React, { useState } from 'react';
import { Modal, notification } from "antd";
import { UserCheckOutApi } from "../Api/UserApis";
import { ROUTES_URL } from "../Constants/navigationUrls";

const { confirm } = Modal;

export const showConfirm = async (formData) => {
  confirm({
    title: `Visitor ${formData.name} with mobile number ${formData.mobile} has not checked out for ${formData.checkinDate}.`,
    content: ` ${formData.accesscardno !== null ? `Please collect the access card with access card number ${formData.accesscardno}. 
           If it has been collected, please select "yes" to proceed with the checkout.` : 'Do you want to checkout?'}`,
           okText:  formData.accesscardno !== null ? 'Yes' : 'Checkout',
           cancelText: 'Cancel',
    async onOk() {
      try {
        if (formData.accesscardno !== null) {
          formData.accessCardCollected = true;
        }
        const responseData = await UserCheckOutApi(formData);
        if(responseData.success===true){
          notification.success({
            message: 'Proceeding',
            description: `The checkout process for Visitor ${formData.name} has been completed`,
          });
        }

      } catch (error) {
        notification.error({
          message: 'Checkout Failed',
          description: `An error occurred during checkout: ${error.message}`,
        });
      }
    },
    onCancel() {
      notification.info({
        message: 'Cancelled',
        description: 'You chose not to proceed.',
      });
    },
  });
};

export const handleApiResponse = async (responseData, navigate, showConfirm,mobile) => {
  if (responseData.success) {
    if (responseData.page === "EntryPage") {
      const stateData = responseData.data ? responseData.data : { mobile };
      navigate(ROUTES_URL.USER_CHECK_IN_PAGE, { state: stateData });
    } else if (responseData.page === "notCheckedOutPage") {
    
      showConfirm(responseData.data);
    }
  } else {
    // Handle error or other cases if needed
  }
};
