const VISITOR_ENTERY_PAGE_FIELDS = {
    mobile: {
        name: "mobile",
        label: "Phone number",
        message: "Please input your phone number!",
        pattern:/^[0-9]{10}$/,
        patternMessage:"Phone number must be a 10-digit number!",
        layout: "vertical",
        required: true,
        maxLength: 10,
        
    }
}

module.exports = VISITOR_ENTERY_PAGE_FIELDS;