import React from 'react';
import { Form, Input } from 'antd';

const AntdInputFormItem = ({  item }) => {
  return (
    <Form.Item
      layout={item.layout}
      label={item.label}
      name={item.name}
      initialValue={item.initialValue} // Set initial value
      rules={[{ required: item.required, message: item.message },
        ...(item.pattern ? [{ pattern: item.pattern, message: item.patternMessage }] : []), // Add custom pattern validation if provided
      ]}
    >
  {item.type === 'password' ? (
        <Input.Password
          size={item.size}
          readOnly={item.readOnly}
          maxLength={item.maxLength}
          placeholder={item.placeholder}
        />
      ) : (
        <Input
          size={item.size}
          readOnly={item.readOnly}
          maxLength={item.maxLength}
          placeholder={item.placeholder}
        />
      )}
    </Form.Item>
  );
};

export default AntdInputFormItem;
