import { message } from 'antd';
import { VisitorAppLoginApi } from '../Api/UserApis'; // Ensure this path is correct
import { ROUTES_URL } from '../Constants/navigationUrls';
import CryptoJS from 'crypto-js';
export const handleFinish = async (values, login, navigate,setLoading) => {
  
  try {
    setLoading(true);
    const formData = {
      username: values.username,
      password: values.password,
    };

    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(formData), SECRET_KEY).toString();
   

    // Call the API with the encrypted form data
    const response = await VisitorAppLoginApi({ data: encryptedData });
    
    // Check if the response is successful
    
    if (response.success) {
      message.success('Login successful');
      login(); // Update authentication state
      navigate(ROUTES_URL.USER_ENTRY_PAGE);
      setLoading(false);
    } else {
      message.error(response.message || 'Login failed');
      setLoading(false);
    }
  } catch (error) {
    message.error('Invalid username or password');
    setLoading(false);
  }
};

export const handleFinishFailed = (errorInfo) => {
  // console.log('Failed:', errorInfo);
};
