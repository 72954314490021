const YES_NO_OPTIONS = [
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" },
];
const LoCATION_OPTIONS = [
  { name: "mangalore", value: "mangalore", label:"Mangalore" },
  { name: "udupi", value: "udupi" ,label:"Udupi"},
];
const GOVERNMENT_ID_ROOF_OPTIONS = [
  { label: "Driving Licence", value: "Driving Licence", name: "drivingLicence" },
  { label: "Aadhaar Card", value: "Aadhaar Card", name: "aadhaarCard" },
  { label: "PAN Card", value: "PAN Card", name: "panCard" },
  { label: "Other", value: "Other", name: "other" },
]
const ADD_VISTOR_FIELDS = {
  name: {

    name: "name",
    label: "Visitor Name",
    message: "Please Enter Visitor Name",
    layout: "vertical",
    required: true,
    size: "large"
  },
  mobile: {
    name: "mobile",
    label: "Mobile Number",
    layout: "vertical",
    required: false,
    readOnly: true,
    size: "large"
  },
  host: {
    name: 'host',
    label: 'Host',
    layout: 'vertical',
    required: true,
    message: "Please select a host name",
    mode: "multiple"
  },
  puspose: {
    name: 'purpose',
    label: 'Purpose',
    layout: 'vertical',
    message: "Please select a host purpose",
    required: true,
    showSearch:"showSearch"
  },
  laptop: {
    name: 'laptop',
    label: 'Laptop',
    layout: 'vertical',
    message: "Please select a laptop option: Yes or No",
    required: true,
    options: YES_NO_OPTIONS,

  },
  accessCard: {
    name: 'accessCard',
    label: 'Access Card',
    layout: 'vertical',
    message: "Please select a Access Card option: Yes or No",
    required: true,
    options: YES_NO_OPTIONS,

  },
  governmentIdProof: {
    name: 'governmentIdProof',
    label: 'Government ID Proof',
    layout: 'vertical',
    message: "Please select a government ID proof",
    required: true,
    options: GOVERNMENT_ID_ROOF_OPTIONS,

  },
  governmentIdProofValue: {
    name: "governmentIdProofValue",
    label: "Government ID Proof (Last 4 Digits)",
    message: "Please enter the last 4 digits of the Government ID.",
    layout: "vertical",
    required: true,
    size: "large",
    maxLength: 4,
    pattern: /^[0-9]{4}$/,
    patternMessage: "Please enter the last 4 digits of the Government ID",
  },
  governmentIdProofName: {
    name: "governmentIdProofName",
    label: "Government ID Proof Name",
    message: "Please enter the name of the Government ID.",
    layout: "vertical",
    required: true,
    size: "large",
    maxLength: 15,
  },
  accessCardNo: {
    name: "accessCardNo",
    label: "Access Card No",
    message: "Please Enter Access Card No",
    layout: "vertical",
    required: true,
    size: "large",
    maxLength: 15,
  },
  location: {
    name: 'location',
    label: 'Location',
    layout: 'vertical',
    message: "Please select a Location",
    required: true,
    options: LoCATION_OPTIONS,

  },
};

module.exports = ADD_VISTOR_FIELDS;
