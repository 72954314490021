import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';

const AntdSelectFormItem = ({ item, apiCall,style }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
 
  useEffect(() => {
    const fetchOptions = async () => {
      if (apiCall) {
        try {
          const response = await apiCall();
          setOptions(Array.isArray(response) ? response : []); // Ensure options is an array
        } catch (error) {
          console.error("Error fetching options:", error);
          setOptions([]);
        } finally {
          setLoading(false);
        }
      } else {
        setOptions(Array.isArray(item.options) ? item.options : []); // Ensure options is an array
        setLoading(false);
      }
    };
    fetchOptions();
  }, [apiCall, item.options]);
  const defaultStyle = { textAlign: 'left' };
  const formItemRules = item.readOnly ? [] : [{ required: item.required, message: item.message }];
  return (
    <Form.Item
      label={item.label}
      name={item.name}
      rules={formItemRules}
      layout={item.layout}
    >
      <Select  defaultValue={item.initialValue} onChange={item.onChange} size="large" mode={item.mode} loading={loading}  showSearch={item.showSearch}        disabled={item.readOnly} style={{ ...defaultStyle, ...style }}  >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default AntdSelectFormItem;



