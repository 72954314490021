import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTES_URL } from '../../Constants/navigationUrls';
const PageNotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Result style={{background: "#d9edff"}}
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist." 
      extra={
        <Button type="primary" onClick={() => navigate(ROUTES_URL.USER_ENTRY_PAGE)}>
          Back Home
        </Button>
      }
    />
  );
};

export default PageNotFoundPage;
