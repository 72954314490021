import React, { useState }  from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { PhoneOutlined ,CloseOutlined, HomeOutlined} from "@ant-design/icons";
import { UserEntryScreenWrap } from "./UserEntryPage.styles";
import { Images } from "../../Assets/images";
import { UserCheckInCheckOutApi } from "../../Api/UserApis";
import { useNavigate } from 'react-router-dom';
import { handleApiResponse, showConfirm } from "../../Helpers/UserEntryPageHelper";
import VISITOR_ENTERY_PAGE_FIELDS from "../../Constants/VISITOR_ENTERY_PAGE_FIELDS";
import {AntdInputFormItem, AntdSpin} from "../../Components";
import { Link } from "react-router-dom";
import { ROUTES_URL } from "../../Constants/navigationUrls";

const UserEntryPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State to manage loading

  const onFinish = async (values) => {
    setLoading(true); // Start loading spinner
    const mobile = `+91${values.mobile}`;
    const formData = { mobile };

    try {
      const responseData = await UserCheckInCheckOutApi(formData);
      await handleApiResponse(responseData, navigate, showConfirm, mobile);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleCloseBrowser = () => {
    window.close();
  };

  return (
    (
      loading ? ( <AntdSpin/>):(

    <UserEntryScreenWrap>
      <div className="user-entry-screenwrap">
        <div className="visitor-form-container">
          <div className="user-checkIn-close">
          <HomeOutlined
                onClick={handleCloseBrowser}
                style={{ color: "#000", fontSize: "30px", cursor: "pointer" }}
              />
              <Link to={ROUTES_URL.LOGIN_PAGE} >
          <CloseOutlined
            style={{ right: 10, top: 10, color: "#000", fontSize: "30px" }}
          />
        </Link>
          </div>
        
          <h2>
            Welcome to <span className="highlight">Visitor App</span>
          </h2>
          <h3>Visitor's phone number</h3>
          <img src={Images.mobileAppImage} alt="Mobile App" className="form-image" />
          <div className="form-card">
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label="Code">
                    <Input defaultValue="+91" readOnly prefix={<PhoneOutlined />} />
                  </Form.Item>
                </Col>
                <Col span={18}>
                <AntdInputFormItem item={VISITOR_ENTERY_PAGE_FIELDS.mobile}/>
        
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="form-submit-button">
                  Next
                </Button>
              </Form.Item>
              {/* <Form.Item>
                <Button type="primary" onClick={handleCloseBrowser} className="form-submit-button">
                  Back to Home
                </Button>
              </Form.Item> */}
            </Form>
          </div>
        </div>
      </div>
    </UserEntryScreenWrap>)
  )
);
};

export default UserEntryPage;