import React from 'react';
import { Card, Typography, Divider, Row, Col, Avatar } from 'antd';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { UserCheckInSuccessScreenWrap } from './UserCheckInSuccessPage.styles';
import { Link, useLocation } from "react-router-dom";
import moment from 'moment-timezone';
import { ROUTES_URL } from "../../Constants/navigationUrls";
import { useRedirectAfterDelay } from '../../Helpers/UserCheckInSuccessPageHelper';

const UserCheckInSuccessPage = () => {
  const TIMEZONE = "Asia/Kolkata";
  const { Title, Text } = Typography;
  const location = useLocation();
  const { data } = location.state || {}; // Retrieve passed data
  const formattedCheckInDate =  data ? moment(data.checkIn).tz(TIMEZONE).format('dddd, D MMMM YYYY, h:mm A') : "";

  useRedirectAfterDelay(30000); // Redirect after 30 seconds

  return (
    <UserCheckInSuccessScreenWrap>
      <div className="visitor-card-container">
        <Card className='visitor-card-container-card'
          cover={
            <div
              className='visitor-card-container-card-div'
              style={{
                backgroundColor: '#00C853',
                padding: '20px 0',
                position: 'relative',
              }}
            >
              <Link to={ROUTES_URL.USER_ENTRY_PAGE}>
                <CloseOutlined className='visitor-card-close-icon' style={{ position: 'absolute', right: 10, top: 10, color: '#fff', fontSize: '30px' }} />
              </Link>
              <Avatar
                size={64}
                icon={<CheckCircleOutlined />}
                style={{ backgroundColor: '#fff', color: '#00C853' }}
              />
              <Title level={3} style={{ color: '#fff', margin: 0 }}>
                {data ? data.name : ""}
              </Title>
            </div>
          }
        >
          <Title level={4} style={{ margin: '10px 0' }}>
            Checked In At
          </Title>
          <Divider />
          <Row>
            <Col span={24}>
              <Title level={4} style={{ margin: '5px 0' }}>
                {formattedCheckInDate}
              </Title>
            </Col>
            <Col span={24}>
              <Text style={{ color: '#888' }}>Niveus Solutions</Text>
            </Col>
          </Row>
        </Card>
      </div>
    </UserCheckInSuccessScreenWrap>
  );
}

export default UserCheckInSuccessPage;
