import React, { useState ,useEffect} from "react";
import { AntdInputFormItem, AntdSelectFormItem, AntdSpin } from "../../Components";
import ADD_VISTOR_FIELDS from "../../Constants/ADD_VISTOR_FIELDS";
import { CloseOutlined } from "@ant-design/icons";
import { UserCheckInScreenWrap } from "./UserCheckInPage.styles";
import { Form, Button } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES_URL } from "../../Constants/navigationUrls";
import { UserFetchUserInfoApi, UserGetAllPurposeApi } from "../../Api/UserApis";
import moment from 'moment-timezone';
import {
  initializeFieldsWithInitialValues,
  handleDropdownChangeHelper,
  handleSubmitHelper
} from "../../Helpers/UserCheckInPageHelper";
import { DynamicFormItems } from "../../Components";

const UserCheckInPage = () => {
  
  const TIMEZONE = "Asia/Kolkata";
  const navigate = useNavigate();
  const currentTime = moment().tz(TIMEZONE).format("YYYY-MM-DD HH:mm:ss");
  const location = useLocation();
  const fieldsWithInitialValues = initializeFieldsWithInitialValues(location, ADD_VISTOR_FIELDS);

  const [selectedOption, setSelectedOption] = useState("");
  const [showTextField, setShowTextField] = useState(false);

  const [selectedOption1, setSelectedOption1] = useState("");
  const [showTextField1, setShowTextField1] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading

  const [selectedGovernmentIdProof, setSelectedGovernmentIdProof] = useState("");
  const [showGovIdValueField, setShowGovIdValueField] = useState(false);
  const [showOtherIdFields, setShowOtherIdFields] = useState(false);

  const handleDropdownChange = (value) => {
    handleDropdownChangeHelper(value, setSelectedOption, setShowTextField);
  };

  const handleDropdownChange1 = (value) => {
    handleDropdownChangeHelper(value, setSelectedOption1, setShowTextField1);
  };

  const handleSubmit = async (values) => {
    setLoading(true); // Show spinner
    try {
      await handleSubmitHelper(values, currentTime, navigate);
    } finally {
      setLoading(false); // Hide spinner
    }
  };
  const handleGovIdChange = (value) => {
    setSelectedGovernmentIdProof(value)

    if (["Driving Licence", "Aadhaar Card", "PAN Card"].includes(value)) {
      setShowGovIdValueField(true);
      setShowOtherIdFields(false);
    } else if (value === "Other") {
      setShowGovIdValueField(false);
      setShowOtherIdFields(true);
    } else {
      setShowGovIdValueField(false);
      setShowOtherIdFields(false);
    }
  };

  useEffect(() => {
    // Initialize state based on location.state
    const restState = location.state || {};
    const governmentIdProof = restState.governmentidproof || '';
    setSelectedGovernmentIdProof(governmentIdProof);

    // Set visibility based on initial governmentIdProof value
    if (["Driving Licence", "Aadhaar Card", "PAN Card"].includes(governmentIdProof)) {
      setShowGovIdValueField(true);
      setShowOtherIdFields(false);
    } else if (governmentIdProof === 'Other') {
      setShowGovIdValueField(false);
      setShowOtherIdFields(true);
    } else {
      setShowGovIdValueField(false);
      setShowOtherIdFields(false);
    }
  }, [location.state]); // Dependency on location.state to re-run when it changes
  return (
    loading ? (
      <AntdSpin /> // Show spinner while loading
    ) : (
      <UserCheckInScreenWrap>
        <div className="user-checkIn-screen-wrap">
          <Link to={ROUTES_URL.USER_ENTRY_PAGE} className="user-checkIn-close">
            <CloseOutlined style={{ right: 10, top: 10, color: "#000", fontSize: "30px" }} />
          </Link>
          <h2>Please fill the visitor Details</h2>

          <Form
            name="addVisitorForm"
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <AntdInputFormItem item={fieldsWithInitialValues.name} />
            <AntdInputFormItem item={fieldsWithInitialValues.mobile} />
            <AntdSelectFormItem item={ADD_VISTOR_FIELDS.host} apiCall={UserFetchUserInfoApi} />
            <AntdSelectFormItem item={ADD_VISTOR_FIELDS.puspose} apiCall={UserGetAllPurposeApi} />
            <AntdSelectFormItem item={{ ...ADD_VISTOR_FIELDS.laptop, onChange: handleDropdownChange }} />

            <DynamicFormItems
              showTextField={showTextField}
              formFields="laptopSerialNo"
              itemLabel="Laptop Serial Number"
              addLabel="Add Laptop serial number"
            />

            <AntdSelectFormItem item={{ ...ADD_VISTOR_FIELDS.accessCard, onChange: handleDropdownChange1 }} />
            {showTextField1 && <AntdInputFormItem item={fieldsWithInitialValues.accessCardNo} />}
            <AntdSelectFormItem
              item={{
                ...fieldsWithInitialValues.governmentIdProof,value:selectedGovernmentIdProof,
                onChange: handleGovIdChange
              }}
            />

            {/* Conditionally Render Text Fields Based on Selection */}
            {showGovIdValueField && (
              <AntdInputFormItem item={fieldsWithInitialValues.governmentIdProofValue} />
            )}

            {showOtherIdFields && (
              <>
                <AntdInputFormItem item={fieldsWithInitialValues.governmentIdProofName} />
                <AntdInputFormItem item={fieldsWithInitialValues.governmentIdProofValue} />
              </>
            )}
            <AntdSelectFormItem item={{ ...ADD_VISTOR_FIELDS.location }} />

            <Form.Item className="button">
              <Button type="primary" size="large" htmlType="submit" className="form-submit-button">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </UserCheckInScreenWrap>
    )
  );
};

export default UserCheckInPage;

