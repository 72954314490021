import styled from "styled-components";

export const UserCheckInSuccessScreenWrap = styled.main`
  background: #d9edff;
  .visitor-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .visitor-card-container-card {
    min-width: 40vw;
    width: 300px;
    text-align: center;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
    overflow: hidden;
  }
  .ant-card-cover {
    border-radius: 15px 15px 0 0;
  }
`;
