import { ROUTES_URL } from '../Constants/navigationUrls';
import { AntdShowNotification } from '../Components';
import { UserCheckIntApi } from '../Api/UserApis';

export const initializeFieldsWithInitialValues = (location, ADD_VISTOR_FIELDS) => {
  const { mobile, ...restState } = location.state || {};

  return {
    ...ADD_VISTOR_FIELDS,
    mobile: {
      ...ADD_VISTOR_FIELDS.mobile,
      initialValue: mobile,
      readOnly: !!mobile // Read-only if mobile is present
    },
    name: {
      ...ADD_VISTOR_FIELDS.name,
      initialValue: restState.name || '',
      readOnly: !!restState.name // Read-only if name is present
    },
    governmentIdProof: {
      ...ADD_VISTOR_FIELDS.governmentIdProof,
      initialValue: restState.governmentidproof || '', // Ensure initial value is set correctly
      readOnly: !!restState.governmentidproof
    },
    governmentIdProofName: {
      ...ADD_VISTOR_FIELDS.governmentIdProofName,
      initialValue: restState.governmentidproofname || '',
      readOnly: !!restState.governmentidproofname
    },
    governmentIdProofValue: {
      ...ADD_VISTOR_FIELDS.governmentIdProofValue,
      initialValue: restState.governmentidproofvalue || '',
      readOnly: !!restState.governmentidproofvalue
    }
  };
};

export const handleDropdownChangeHelper = (value, setSelectedOption, setShowTextField) => {
  setSelectedOption(value);
  setShowTextField(value === "Yes");
};

export const handleSubmitHelper = async (values, currentTime, navigate) => {
  const updatedValues = {
    ...values,
    checkIn: currentTime,
  };

  const responseData = await UserCheckIntApi(updatedValues);
  // Navigate to the success page if the submission is successful
  if (responseData && responseData.success && responseData.data) {
    navigate(ROUTES_URL.USER_CHECK_IN_SUCCESS_PAGE, { state: { data: responseData.data } });
  } else {
    AntdShowNotification({
      message: 'Notification',
      description: 'User has already checked out for today'
    });
  }
};
