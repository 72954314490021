import React from 'react';
import { Spin } from 'antd';

const AntdSpin = () => {
  const containerStyle = {
    height: '100vh',  // Full height of the viewport
    width: '100vw',   // Full width of the viewport
    display: 'flex',  // Use flexbox layout
    justifyContent: 'center',  // Center horizontally
    alignItems: 'center',      // Center vertically
    backgroundColor: '#fff',   // Optional: background color
  };

  return (
    <div style={containerStyle}>
      <Spin size="large"   />
    </div>
  );
};

export default AntdSpin;
