// components/DynamicFormItems.js
import React from "react";
import { Form, Button, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const DynamicFormItems = ({ showTextField, formFields, addLabel, itemLabel }) => (
  showTextField && (
    <Form.List name={formFields} initialValue={['']}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              key={field.key}
              label={index === 0 ? itemLabel : ""}
              required
            >
              <Form.Item
                {...field}
                name={[field.name]}
                fieldKey={[field.fieldKey]}
                noStyle
                rules={[{ required: true, message: `Please input ${itemLabel.toLowerCase()}` }]}
              >
                <Input size="large" placeholder={`Enter ${itemLabel.toLowerCase()}`} />
              </Form.Item>
              {fields.length > 1 && (
                <Button
                  type="link"
                  onClick={() => remove(field.name)}
                  icon={<MinusCircleOutlined />}
                >
                  Remove
                </Button>
              )}
            </Form.Item>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              {addLabel}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
);

export default DynamicFormItems;
