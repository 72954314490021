import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES_URL } from '../Constants/navigationUrls';
import { useAuth } from '../Context/AuthContext';

const ProtectedRoute = ({ element }) => {
    const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated) {
    return <Navigate to={ROUTES_URL.LOGIN_PAGE} />;
  }
  
  return element;
};

export default ProtectedRoute;