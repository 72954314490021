// Notification.js
import { notification } from 'antd';
import React from 'react';

const AntdShowNotification = ({ message, description }) => {
  notification.info({
    message: message,
    description: description,
    placement: 'top',
    duration: 3, // Duration in seconds

  });
  return null; // This component does not render anything
};

export default AntdShowNotification;
