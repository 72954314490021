const LOGIN_VISITOR_FIELDS = {
  username: {
    name: "username",
    message: "Please input your username!",
    placeholder:"Username",
    layout: "vertical",
    required: true,
  },
  password: {
    name: "password",
    message:"Please input your password!",
    placeholder:"Password",
    layout: "vertical",
    required: true,
    type:"password"

  },

};

module.exports = LOGIN_VISITOR_FIELDS;
