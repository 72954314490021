import styled from "styled-components";

export const UserCheckInScreenWrap = styled.main`
  
    display: flex;
    justify-content: center;
    // width: 100vw;
    // height: 100vh;
    align-items: center;
      background: #d9edff;
      
  .user-checkIn-screen-wrap{
    // width: 80%;
    min-width: 50vw;
    max-width: 70vw;
    background: white;
    box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
    padding: 14px 24px;
    margin: 16px;
    border-radius: 20px;
    background-color: #ffffff
     align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
    .form-submit-button{
     width:15vh
    }
     .user-checkIn-close{
      display: flex;
    color: unset;
    text-decoration: none;
    justify-content: flex-end;}
`;
