import React from 'react';
import { ROUTES_URL } from './Constants/navigationUrls';
import { PageNotFoundPage, UserEntryPage, UserCheckInSuccessPage, UserCheckInPage, LoginPage } from './Pages';
import ProtectedRoute from './Components/ProtectedRoute';

const routes = [
  {
    path: ROUTES_URL.PAGE_NOT_FOUND,
    element: <PageNotFoundPage />,
  },
  {
    path: ROUTES_URL.USER_ENTRY_PAGE,
    element: <ProtectedRoute element={<UserEntryPage />} />,
  },
  {
    path: ROUTES_URL.USER_CHECK_IN_PAGE,
    element: <ProtectedRoute element={<UserCheckInPage />} />,
  },
  {
    path: ROUTES_URL.USER_CHECK_IN_SUCCESS_PAGE,
    element: <ProtectedRoute element={<UserCheckInSuccessPage />} />,
  },
  {
    path: ROUTES_URL.LOGIN_PAGE,
    element: <LoginPage />,
  },
];

export default routes;
